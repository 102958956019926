@import "common/styles/sizes.scss";
@import "common/styles/functions";

.home-page__home-page__home-page {
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: auto 1fr;
  gap: $homepage-gap;
  padding: 0 $homepage-gap 0 $homepage-gap;
  &__cover {
    width: 100%;
    background-color: rgba(white, 0.1);
    min-height: 14vw;
  }

  &__cover-image {
    opacity: 0;
    width: 100%;
    transition: opacity 300ms;
    &--loaded {
      opacity: 1;
    }
    //height: 100%;
  }

  &__publications {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: $homepage-gap;
  }

  &__loading-text {
    text-align: center;
    grid-column: 1/-1;
    font-size: 30px;
    font-weight: 400;
    opacity: 0.4;
    margin-top: 1em;
  }
  @media only screen and (max-width: 1300px) {
    &__publications {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media only screen and (max-width: 930px) {
    &__publications {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media only screen and (max-width: $mobile-max-width) {
    &__cover {
      min-height: auto;
    }
    &__publications {
      grid-template-columns: 1fr;
    }
  }

  @media only screen and (min-width: 1950px) {
    $homepage-gap-in-vw: convert-px-to-vw($homepage-gap);
    gap: $homepage-gap-in-vw;
    padding: 0 $homepage-gap-in-vw 0 $homepage-gap-in-vw;

    &__publications {
      grid-template-columns: repeat(5, 1fr);
      gap: $homepage-gap-in-vw;
    }

    &__loading-text {
      font-size: convert-px-to-vw(30);
    }
  }
}
