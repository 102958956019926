@import "common/styles/functions";

.core__footer__footer {
  text-align: center;
  font-size: 16px;
  padding: 1em;
  height: 100px;
  display: grid;
  align-content: center;

  @media only screen and (min-width: 1950px) {
    height: convert-px-to-vw(100);
    font-size: convert-px-to-vw(16);
  }
}
