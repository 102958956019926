@import "common/styles/functions";

.publication__image__image {
  border: 10px solid white;
  background-color: white;
  min-height: 597px;
  &__image {
    opacity: 0;
    transition: opacity 300ms;
    &--loaded {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 480px) {
    border: none;
    min-height: 100vw;
  }

  @media only screen and (min-width: 1950px) {
    border-width: convert-px-to-vw(10px);
    min-height: convert-px-to-vw(597px);
  }
}
