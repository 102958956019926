@import "common/styles/functions";

.publication__publication__publication {
  display: grid;
  justify-content: center;
  align-content: center;

  &__loading-text {
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    opacity: 0.4;
    margin-top: 1em;
  }
  &__content {
    display: grid;
    grid-template-columns: 420px 420px;
    gap: 20px;
  }

  @media only screen and (max-width: 1000px) {
    padding-top: 20px;
    &__content {
      grid-template-columns: 420px;
    }
  }
  @media only screen and (max-width: 480px) {
    padding: 0 20px 20px 20px;
    &__content {
      grid-template-columns: 100%;
    }
  }

  @media only screen and (min-width: 1950px) {
    &__content {
      grid-template-columns: convert-px-to-vw(420) convert-px-to-vw(420);
    }
  }
}
