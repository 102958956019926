@import "common/styles/colors.scss";
@import "common/styles/functions";
@import "common/styles/sizes";

.core__app__app {
  min-height: 100vh;
  padding-top: $header-height;
  display: grid;
  //gap: 20px;
  grid-template-rows: 1fr auto;

  @media only screen and (max-width: 560px) {
    padding-top: 18vw;
  }

  @media only screen and (min-width: 1950px) {
    padding-top: convert-px-to-vw($header-height);
  }
}
