@import "common/styles/colors";
@import "common/styles/functions";

.publication__info__info {
  &__title {
    font-family: "Anton", sans-serif;
    color: $background;
    background-color: $custom-white;
    line-height: 1.5em;
    font-weight: 400;
    display: inline-block;
    padding: 0.5em;
    font-size: 34px;
  }

  &__publication-date {
    font-size: 18px;
    margin-top: 1em;
    font-weight: 600;

    border-bottom: 1px solid white;
    padding-bottom: 0.3em;
    display: inline-block;
  }

  &__social-networks {
    margin: 1em 0 1.4em 0;
  }

  &__navigation {
    margin-top: 1em;
  }

  &__back-button {
    margin-top: 2.4em;
  }

  @media only screen and (max-width: 1000px) {
    text-align: center;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: max-content;
    justify-items: center;

    br {
      display: none;
    }

    &__navigation {
      order: 0;
      margin-bottom: 2em;
    }
    &__title {
      order: 1;
    }
    &__publication-date {
      order: 2;
    }
    &__social-networks {
      order: 3;
      margin-bottom: 1em;
    }
    &__back-button {
      order: 4;
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 480px) {
    &__navigation {
      margin-top: 0.1em;
    }

    &__title {
      font-size: 8vw;
    }
    &__publication-date-previous-text {
      display: none;
    }
  }

  @media only screen and (min-width: 1950px) {
    &__title {
      font-size: convert-px-to-vw(34);
    }
    &__publication-date {
      font-size: convert-px-to-vw(18);
      border-bottom-width: convert-px-to-vw(1);
    }
  }
}
