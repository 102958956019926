@import "common/styles/colors.scss";
@import "common/styles/functions";
@import "common/styles/sizes";

.core__header__header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 20px;
  height: $header-height;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  background-color: $background;
  align-content: center;
  align-items: center;

  /*&__title {
    display: grid;
    align-content: center;
  }*/

  &__logo {
    width: 100%;
    max-width: 460px;
    height: auto;
    image-rendering: pixelated;
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr auto;
  }
  @media only screen and (max-width: 560px) {
    height: 18vw;
  }
  @media only screen and (min-width: 1950px) {
    padding: convert-px-to-vw(20);
    height: convert-px-to-vw($header-height);
    &__logo {
      max-width: convert-px-to-vw(460);
    }
  }
}
