@import "common/styles/colors.scss";
@import "common/styles/functions";

.core__header__navigation-links {
  display: grid;
  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 4px;
  &__link {
    color: $custom-white;
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px 0 15px;
    text-decoration: none;
    user-select: none;
    position: relative;
    line-height: 2em;
    font-weight: 700;

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 0;
      height: 5px;
      bottom: 0;
      left: 0;
      background-color: $custom-white;
      transition: width 200ms;
    }
    &.active {
      background-color: $custom-white;
      color: $background;
    }
    &:hover:not(.active) {
      &:before {
        width: 100%;
      }
    }
  }

  @media only screen and (max-width: 1000px) {
    display: none;
  }

  @media only screen and (min-width: 1950px) {
    &__link {
      $padding-in-vw: convert-px-to-vw(15);
      font-size: convert-px-to-vw(16);
      padding: 0 $padding-in-vw 0 $padding-in-vw;

      &:before {
        height: convert-px-to-vw(3);
      }
    }
  }
}
