@import "common/styles/colors.scss";
@import "common/styles/functions";

.about__about__about {
  display: grid;
  grid-template-columns: 400px 400px;
  position: relative;

  justify-content: center;
  gap: 40px;

  &__loading-text {
    text-align: center;
    grid-column: 1/-1;
    font-size: 30px;
    font-weight: 400;
    opacity: 0.4;
    margin-top: 1em;
  }

  &__title {
    font-family: "Anton", sans-serif;
    font-size: 50px;
    font-weight: 400;
    grid-column-start: 1;
    grid-column-end: -1;
    text-align: center;
    margin: 0.4em 0 0.4em 0;
    padding: 20px;
    background-color: $custom-white;
    color: $background;
  }

  &__text {
    line-height: 1.7em;
    font-size: 16px;
    p + p {
      margin-top: 1.7em;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 1000ms;
    &--loaded {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin: 1em 2em 1em 2em;
    &__title {
      line-height: 1.5em;
      font-size: 10vw;
    }
  }

  @media only screen and (min-width: 1950px) {
    $column-width: convert-px-to-vw(400);
    grid-template-columns: $column-width $column-width;
    gap: convert-px-to-vw(40);

    &__loading-text {
      font-size: convert-px-to-vw(30);
    }

    &__title {
      font-size: convert-px-to-vw(50);
      padding: convert-px-to-vw(20);
    }
    &__text {
      font-size: convert-px-to-vw(16);
    }
  }
}
