@import "common/styles/colors";
@import "common/styles/functions";

.publication__info__navigation {
  $space-between-buttons: 6px;
  $button-icon-size: 20px;
  font-size: 18px;
  color: $custom-white;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: $space-between-buttons;

  &__text {
    display: inline-block;
    min-width: 5em;
    color: $custom-white;
    text-align: center;
    padding: 0.1em 0.3em 0 0.3em;
    font-weight: 700;
    line-height: 1.5em;
  }

  &__button {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    text-align: center;
    border-radius: 0.2em;
    border: none;
    outline: none;
    user-select: none;
    font-size: $button-icon-size;
    background-color: $custom-white;
    color: $background;
    cursor: pointer;
    transition: transform 200ms;

    &:hover {
      box-shadow: 0 0 0.5em #bdcad1;
      transform: scale(1.1);
    }
  }

  @media only screen and (min-width: 1950px) {
    font-size: convert-px-to-vw(18);
    gap: convert-px-to-vw($space-between-buttons);

    &__button {
      font-size: convert-px-to-vw($button-icon-size);
    }
  }

  @media only screen and (max-width: 1000px) {
    justify-content: center;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: auto auto 1fr auto auto;
    width: 100%;
    gap: 2vw;
    &__text {
      font-size: 5vw;
      line-height: 2.1em;
      width: 100%;
    }

    &__button {
      font-size: 7vw;
    }
  }
}
