@import "common/styles/colors";

.core__error-page__error-page {
  text-align: center;
  &__title {
    font-size: 50vw;
    line-height: 1em;
    padding-top: 0;
    margin: 0;
    font-weight: 700;
  }
  &__subtitle {
    font-size: 10vw;
    line-height: 1em;
    padding-top: 0;
    margin: 0;
    font-weight: 700;
    color: $background;
    background-color: $custom-white;
  }
}
