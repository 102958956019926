@import "common/styles/colors";
@import "common/styles/functions";

.publication__info__social-networks {
  $icon-size: 30px;
  font-size: $icon-size;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 0.6em;

  &__link {
    color: $custom-white;
    font-size: $icon-size;
    transition: transform 300ms, filter 300ms;
    &:hover {
      filter: drop-shadow(0 0 0.5em #bdcad1);
      transform: rotate(360deg);
    }
  }

  @media only screen and (min-width: 1950px) {
    font-size: convert-px-to-vw($icon-size);
    &__link {
      font-size: convert-px-to-vw($icon-size);
    }
  }

  @media only screen and (max-width: 1000px) {
    justify-content: center;
  }
}
