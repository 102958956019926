@import "common/styles/colors";

.core__header__burger-menu {
  user-select: none;
  &__burger-button {
    border: none;
    background-color: transparent;
    color: $custom-white;
    font-size: 30px;
    outline: none;
    cursor: pointer;
    &:hover {
      filter: drop-shadow(0 0 15px #bdcad1);
    }
  }

  &__menu-items {
    display: grid;
    grid-auto-flow: row;
    position: fixed;
    top: 80px;
    right: 0;
    min-width: 200px;
    text-align: center;
    background-color: $background;
    padding: 0.5em;
    border: 1px solid rgba($custom-white, 0.4);
    border-radius: 5px 0 0 5px;
  }

  &__social-network-links {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: center;
  }

  &__link {
    color: $custom-white;
    text-decoration: none;
    line-height: 2em;

    &--navigation {
      font-weight: 700;
      &:hover {
        text-shadow: 0 0 20px $custom-white;
      }
    }
    &--social-network {
      font-size: 20px;
      padding: 0 0.5em 0 0.5em;
      &:hover {
        filter: drop-shadow(0 0 15px #bdcad1);
      }
    }
    &.active {
      background-color: $custom-white;
      color: $background;
    }
  }

  @media only screen and (max-width: 560px) {
    &__burger-button {
      font-size: 7vw;
    }

    &__menu-items {
      top: 17vw;
    }
  }
}
