@import "common/styles/colors";
@import "common/styles/functions";

.publication__info__back-button {
  $this: &;
  color: $background;
  background-color: $custom-white;
  font-size: 18px;
  text-decoration: none;
  padding: 0.5em 1em 0.5em 1em;
  display: inline-block;
  transition: transform 200ms;
  &:hover {
    box-shadow: 0 0 10px #bdcad1;
    transform: scale(1.02);
    #{$this}__icon {
      transform: rotate(-360deg);
    }
  }

  &__text {
    margin-right: 1em;
    font-weight: 700;
  }

  &__icon {
    transition: transform 300ms;
  }

  @media only screen and (min-width: 1950px) {
    font-size: convert-px-to-vw(18);
  }
}
