@import "common/styles/colors.scss";
@import "common/styles/functions";

.core__header__social-network-links {
  $icon-size: 20px;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  justify-self: end;
  &__link {
    padding: $icon-size/2;
    font-size: $icon-size;
    color: $custom-white;
    display: grid;
    align-content: center;
    transition: transform 300ms, filter 300ms;

    &:last-of-type {
      margin-right: -$icon-size/2;
    }
    &:hover {
      filter: drop-shadow(0 0 10px #bdcad1);
      transform: rotate(360deg);
    }
  }
  &__burger-menu {
    display: none;
  }

  @media only screen and (max-width: 1000px) {
    &__link {
      display: none;
    }
    &__burger-menu {
      display: grid;
    }
  }

  @media only screen and (min-width: 1950px) {
    $icon-size-in-vw: convert-px-to-vw(20);
    &__link {
      padding: $icon-size-in-vw/2;
      font-size: $icon-size-in-vw;

      &:last-of-type {
        margin-right: -$icon-size-in-vw/2;
      }
      &:hover {
        filter: drop-shadow(0 0 convert-px-to-vw(10) #bdcad1);
      }
    }
  }
}
