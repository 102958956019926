@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
*,*::before,*::after{box-sizing:border-box}ul[class],ol[class]{padding:0}body,h1,h2,h3,h4,p,ul[class],ol[class],li,figure,figcaption,blockquote,dl,dd{margin:0}body{min-height:100vh;scroll-behavior:smooth;text-rendering:optimizeSpeed;line-height:1.5em}ul[class],ol[class]{list-style:none}a:not([class]){-webkit-text-decoration-skip:ink;text-decoration-skip-ink:auto}img{max-width:100%;display:block}article>*+*{margin-top:1em}input,button,textarea,select{font:inherit}@media (prefers-reduced-motion: reduce){*{-webkit-animation-duration:0.01ms !important;animation-duration:0.01ms !important;-webkit-animation-iteration-count:1 !important;animation-iteration-count:1 !important;transition-duration:0.01ms !important;scroll-behavior:auto !important}}html,body{font-family:"Open Sans", sans-serif;background-color:#02040b;color:#e2e3ea}html ::selection,body ::selection{color:#02040b;background:#e2e3ea}

.common__error-boundary__error-boundary{display:grid;justify-content:center;background-color:red}.common__error-boundary__error-boundary__title{color:white;line-height:2em;padding:0 2em 0 2em;font-size:50px;text-align:center;display:inline-block}.common__error-boundary__error-boundary__details{color:white;line-height:2em;font-size:14px;display:inline-block;padding:20px}

.core__error-page__error-page{text-align:center}.core__error-page__error-page__title{font-size:50vw;line-height:1em;padding-top:0;margin:0;font-weight:700}.core__error-page__error-page__subtitle{font-size:10vw;line-height:1em;padding-top:0;margin:0;font-weight:700;color:#02040b;background-color:#e2e3ea}

.core__header__burger-menu{-webkit-user-select:none;-ms-user-select:none;user-select:none}.core__header__burger-menu__burger-button{border:none;background-color:transparent;color:#e2e3ea;font-size:30px;outline:none;cursor:pointer}.core__header__burger-menu__burger-button:hover{-webkit-filter:drop-shadow(0 0 15px #bdcad1);filter:drop-shadow(0 0 15px #bdcad1)}.core__header__burger-menu__menu-items{display:grid;grid-auto-flow:row;position:fixed;top:80px;right:0;min-width:200px;text-align:center;background-color:#02040b;padding:0.5em;border:1px solid rgba(226,227,234,0.4);border-radius:5px 0 0 5px}.core__header__burger-menu__social-network-links{display:grid;grid-auto-flow:column;grid-auto-columns:-webkit-max-content;grid-auto-columns:max-content;justify-content:center}.core__header__burger-menu__link{color:#e2e3ea;text-decoration:none;line-height:2em}.core__header__burger-menu__link--navigation{font-weight:700}.core__header__burger-menu__link--navigation:hover{text-shadow:0 0 20px #e2e3ea}.core__header__burger-menu__link--social-network{font-size:20px;padding:0 0.5em 0 0.5em}.core__header__burger-menu__link--social-network:hover{-webkit-filter:drop-shadow(0 0 15px #bdcad1);filter:drop-shadow(0 0 15px #bdcad1)}.core__header__burger-menu__link.active{background-color:#e2e3ea;color:#02040b}@media only screen and (max-width: 560px){.core__header__burger-menu__burger-button{font-size:7vw}.core__header__burger-menu__menu-items{top:17vw}}

.core__header__header{display:grid;grid-template-columns:1fr 1fr 1fr;padding:20px;height:100px;width:100%;position:fixed;top:0;z-index:200;background-color:#02040b;align-content:center;align-items:center}.core__header__header__logo{width:100%;max-width:460px;height:auto;-ms-interpolation-mode:nearest-neighbor;image-rendering:-moz-crisp-edges;image-rendering:pixelated}@media only screen and (max-width: 1000px){.core__header__header{grid-template-columns:1fr auto}}@media only screen and (max-width: 560px){.core__header__header{height:18vw}}@media only screen and (min-width: 1950px){.core__header__header{padding:1.04167vw;height:5.20833vw}.core__header__header__logo{max-width:23.95833vw}}

.core__header__navigation-links{display:grid;justify-content:center;align-content:center;grid-auto-flow:column;grid-auto-columns:-webkit-max-content;grid-auto-columns:max-content;grid-gap:4px;gap:4px}.core__header__navigation-links__link{color:#e2e3ea;font-size:16px;font-weight:400;padding:0 15px 0 15px;text-decoration:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;position:relative;line-height:2em;font-weight:700}.core__header__navigation-links__link:before{content:"";display:inline-block;position:absolute;width:0;height:5px;bottom:0;left:0;background-color:#e2e3ea;transition:width 200ms}.core__header__navigation-links__link.active{background-color:#e2e3ea;color:#02040b}.core__header__navigation-links__link:hover:not(.active):before{width:100%}@media only screen and (max-width: 1000px){.core__header__navigation-links{display:none}}@media only screen and (min-width: 1950px){.core__header__navigation-links__link{font-size:.83333vw;padding:0 .78125vw 0 .78125vw}.core__header__navigation-links__link:before{height:.15625vw}}

.core__header__social-network-links{display:grid;grid-auto-flow:column;grid-auto-columns:-webkit-max-content;grid-auto-columns:max-content;justify-self:end}.core__header__social-network-links__link{padding:10px;font-size:20px;color:#e2e3ea;display:grid;align-content:center;transition:-webkit-transform 300ms, -webkit-filter 300ms;transition:transform 300ms, filter 300ms;transition:transform 300ms, filter 300ms, -webkit-transform 300ms, -webkit-filter 300ms}.core__header__social-network-links__link:last-of-type{margin-right:-10px}.core__header__social-network-links__link:hover{-webkit-filter:drop-shadow(0 0 10px #bdcad1);filter:drop-shadow(0 0 10px #bdcad1);-webkit-transform:rotate(360deg);transform:rotate(360deg)}.core__header__social-network-links__burger-menu{display:none}@media only screen and (max-width: 1000px){.core__header__social-network-links__link{display:none}.core__header__social-network-links__burger-menu{display:grid}}@media only screen and (min-width: 1950px){.core__header__social-network-links__link{padding:.52083vw;font-size:1.04167vw}.core__header__social-network-links__link:last-of-type{margin-right:-.52083vw}.core__header__social-network-links__link:hover{-webkit-filter:drop-shadow(0 0 .52083vw #bdcad1);filter:drop-shadow(0 0 .52083vw #bdcad1)}}

.core__footer__footer{text-align:center;font-size:16px;padding:1em;height:100px;display:grid;align-content:center}@media only screen and (min-width: 1950px){.core__footer__footer{height:5.20833vw;font-size:.83333vw}}

.core__app__app{min-height:100vh;padding-top:100px;display:grid;grid-template-rows:1fr auto}@media only screen and (max-width: 560px){.core__app__app{padding-top:18vw}}@media only screen and (min-width: 1950px){.core__app__app{padding-top:5.20833vw}}

.about__about__about{display:grid;grid-template-columns:400px 400px;position:relative;justify-content:center;grid-gap:40px;gap:40px}.about__about__about__loading-text{text-align:center;grid-column:1/-1;font-size:30px;font-weight:400;opacity:0.4;margin-top:1em}.about__about__about__title{font-family:"Anton", sans-serif;font-size:50px;font-weight:400;grid-column-start:1;grid-column-end:-1;text-align:center;margin:0.4em 0 0.4em 0;padding:20px;background-color:#e2e3ea;color:#02040b}.about__about__about__text{line-height:1.7em;font-size:16px}.about__about__about__text p+p{margin-top:1.7em}.about__about__about__image{width:100%;height:auto;opacity:0;transition:opacity 1000ms}.about__about__about__image--loaded{opacity:1}@media only screen and (max-width: 1000px){.about__about__about{grid-template-columns:1fr;margin:1em 2em 1em 2em}.about__about__about__title{line-height:1.5em;font-size:10vw}}@media only screen and (min-width: 1950px){.about__about__about{grid-template-columns:20.83333vw 20.83333vw;grid-gap:2.08333vw;gap:2.08333vw}.about__about__about__loading-text{font-size:1.5625vw}.about__about__about__title{font-size:2.60417vw;padding:1.04167vw}.about__about__about__text{font-size:.83333vw}}

.home-page__item__item{display:inline-block;width:100%;position:relative;overflow:hidden;outline:0 solid transparent;transition:outline 300ms;background-color:rgba(255,255,255,0.1)}.home-page__item__item__image{width:100%;height:auto;opacity:0;transition:opacity 300ms}.home-page__item__item__image--loaded{opacity:1}

.home-page__home-page__home-page{display:grid;grid-auto-flow:row;grid-template-rows:auto 1fr;grid-gap:20px;gap:20px;padding:0 20px 0 20px}.home-page__home-page__home-page__cover{width:100%;background-color:rgba(255,255,255,0.1);min-height:14vw}.home-page__home-page__home-page__cover-image{opacity:0;width:100%;transition:opacity 300ms}.home-page__home-page__home-page__cover-image--loaded{opacity:1}.home-page__home-page__home-page__publications{display:grid;grid-template-columns:repeat(4, 1fr);justify-content:center;grid-gap:20px;gap:20px}.home-page__home-page__home-page__loading-text{text-align:center;grid-column:1/-1;font-size:30px;font-weight:400;opacity:0.4;margin-top:1em}@media only screen and (max-width: 1300px){.home-page__home-page__home-page__publications{grid-template-columns:repeat(3, 1fr)}}@media only screen and (max-width: 930px){.home-page__home-page__home-page__publications{grid-template-columns:repeat(2, 1fr)}}@media only screen and (max-width: 640px){.home-page__home-page__home-page__cover{min-height:auto}.home-page__home-page__home-page__publications{grid-template-columns:1fr}}@media only screen and (min-width: 1950px){.home-page__home-page__home-page{grid-gap:1.04167vw;gap:1.04167vw;padding:0 1.04167vw 0 1.04167vw}.home-page__home-page__home-page__publications{grid-template-columns:repeat(5, 1fr);grid-gap:1.04167vw;gap:1.04167vw}.home-page__home-page__home-page__loading-text{font-size:1.5625vw}}

.publication__image__image{border:10px solid white;background-color:white;min-height:597px}.publication__image__image__image{opacity:0;transition:opacity 300ms}.publication__image__image__image--loaded{opacity:1}@media only screen and (max-width: 480px){.publication__image__image{border:none;min-height:100vw}}@media only screen and (min-width: 1950px){.publication__image__image{border-width:.52083vw;min-height:31.09375vw}}

.publication__info__back-button{color:#02040b;background-color:#e2e3ea;font-size:18px;text-decoration:none;padding:0.5em 1em 0.5em 1em;display:inline-block;transition:-webkit-transform 200ms;transition:transform 200ms;transition:transform 200ms, -webkit-transform 200ms}.publication__info__back-button:hover{box-shadow:0 0 10px #bdcad1;-webkit-transform:scale(1.02);transform:scale(1.02)}.publication__info__back-button:hover .publication__info__back-button__icon{-webkit-transform:rotate(-360deg);transform:rotate(-360deg)}.publication__info__back-button__text{margin-right:1em;font-weight:700}.publication__info__back-button__icon{transition:-webkit-transform 300ms;transition:transform 300ms;transition:transform 300ms, -webkit-transform 300ms}@media only screen and (min-width: 1950px){.publication__info__back-button{font-size:.9375vw}}

.publication__info__info__title{font-family:"Anton", sans-serif;color:#02040b;background-color:#e2e3ea;line-height:1.5em;font-weight:400;display:inline-block;padding:0.5em;font-size:34px}.publication__info__info__publication-date{font-size:18px;margin-top:1em;font-weight:600;border-bottom:1px solid white;padding-bottom:0.3em;display:inline-block}.publication__info__info__social-networks{margin:1em 0 1.4em 0}.publication__info__info__navigation{margin-top:1em}.publication__info__info__back-button{margin-top:2.4em}@media only screen and (max-width: 1000px){.publication__info__info{text-align:center;display:grid;grid-auto-flow:row;grid-template-rows:-webkit-max-content;grid-template-rows:max-content;justify-items:center}.publication__info__info br{display:none}.publication__info__info__navigation{order:0;margin-bottom:2em}.publication__info__info__title{order:1}.publication__info__info__publication-date{order:2}.publication__info__info__social-networks{order:3;margin-bottom:1em}.publication__info__info__back-button{order:4;margin-top:0}}@media only screen and (max-width: 480px){.publication__info__info__navigation{margin-top:0.1em}.publication__info__info__title{font-size:8vw}.publication__info__info__publication-date-previous-text{display:none}}@media only screen and (min-width: 1950px){.publication__info__info__title{font-size:1.77083vw}.publication__info__info__publication-date{font-size:.9375vw;border-bottom-width:.05208vw}}

.publication__info__navigation{font-size:18px;color:#e2e3ea;display:grid;grid-auto-flow:column;grid-auto-columns:-webkit-max-content;grid-auto-columns:max-content;grid-gap:6px;gap:6px}.publication__info__navigation__text{display:inline-block;min-width:5em;color:#e2e3ea;text-align:center;padding:0.1em 0.3em 0 0.3em;font-weight:700;line-height:1.5em}.publication__info__navigation__button{display:inline-block;width:1.5em;height:1.5em;line-height:1.5em;text-align:center;border-radius:0.2em;border:none;outline:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;font-size:20px;background-color:#e2e3ea;color:#02040b;cursor:pointer;transition:-webkit-transform 200ms;transition:transform 200ms;transition:transform 200ms, -webkit-transform 200ms}.publication__info__navigation__button:hover{box-shadow:0 0 0.5em #bdcad1;-webkit-transform:scale(1.1);transform:scale(1.1)}@media only screen and (min-width: 1950px){.publication__info__navigation{font-size:.9375vw;grid-gap:.3125vw;gap:.3125vw}.publication__info__navigation__button{font-size:1.04167vw}}@media only screen and (max-width: 1000px){.publication__info__navigation{justify-content:center}}@media only screen and (max-width: 480px){.publication__info__navigation{grid-template-columns:auto auto 1fr auto auto;width:100%;grid-gap:2vw;gap:2vw}.publication__info__navigation__text{font-size:5vw;line-height:2.1em;width:100%}.publication__info__navigation__button{font-size:7vw}}

.publication__info__social-networks{font-size:30px;display:grid;grid-auto-flow:column;grid-auto-columns:-webkit-max-content;grid-auto-columns:max-content;grid-gap:0.6em;gap:0.6em}.publication__info__social-networks__link{color:#e2e3ea;font-size:30px;transition:-webkit-transform 300ms, -webkit-filter 300ms;transition:transform 300ms, filter 300ms;transition:transform 300ms, filter 300ms, -webkit-transform 300ms, -webkit-filter 300ms}.publication__info__social-networks__link:hover{-webkit-filter:drop-shadow(0 0 0.5em #bdcad1);filter:drop-shadow(0 0 0.5em #bdcad1);-webkit-transform:rotate(360deg);transform:rotate(360deg)}@media only screen and (min-width: 1950px){.publication__info__social-networks{font-size:1.5625vw}.publication__info__social-networks__link{font-size:1.5625vw}}@media only screen and (max-width: 1000px){.publication__info__social-networks{justify-content:center}}

.publication__publication__publication{display:grid;justify-content:center;align-content:center}.publication__publication__publication__loading-text{text-align:center;font-size:30px;font-weight:400;opacity:0.4;margin-top:1em}.publication__publication__publication__content{display:grid;grid-template-columns:420px 420px;grid-gap:20px;gap:20px}@media only screen and (max-width: 1000px){.publication__publication__publication{padding-top:20px}.publication__publication__publication__content{grid-template-columns:420px}}@media only screen and (max-width: 480px){.publication__publication__publication{padding:0 20px 20px 20px}.publication__publication__publication__content{grid-template-columns:100%}}@media only screen and (min-width: 1950px){.publication__publication__publication__content{grid-template-columns:21.875vw 21.875vw}}

